window.showJobInfo = function showJobInfo(dataTabId){
  let targetTabs = document.querySelectorAll(`[data-tab="${dataTabId}"]`);
  let tabs = document.querySelectorAll(`[data-tab]`);
  if(tabs){
    tabs.forEach(tab =>{
      tab.classList.remove('active')
    })
    targetTabs.forEach(tab =>{
      tab.classList.add('active');
    })
  }
  updatePopupPosition();
}

/*subscribe-popup*/

window.showSubscribePopup = function showSubscribePopup(){

  let popup = document.getElementById('subscribe-popup');
  if(popup){
    popup.classList.add('active')
    scrollLock.disablePageScroll();
  }
}
window.hideSubscribePopup = function hideSubscribePopup(){
  let popup = document.getElementById('subscribe-popup');
  if(popup){
    popup.classList.remove('active');
    scrollLock.enablePageScroll();
  }
}