import Swiper, {Autoplay, Navigation} from 'swiper';
import {swiperObserver} from '../utils/swiper.utils.js';

let countsSwiperWr = document.querySelectorAll('.counts-swiper-wr');
countsSwiperWr.forEach((el) => {
    if (el) {
        let swiperEl = el.querySelector('.swiper');
        let nextEl = el.querySelector('.swiper-button-next');
        let prevEl = el.querySelector('.swiper-button-prev');
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let wrapper = swiperEl.querySelector('.swiper-wrapper');
        let swiper = new Swiper(swiperEl, {
            modules: [Autoplay, Navigation],
            observer: true, //needs to calculate swiper size
            observeParents: true, //needs to calculate swiper size
            spaceBetween: 8,
            slidesPerView: 1,
            speed: 800,
            threshold: 10,
            navigation: {
                nextEl: nextEl,
                prevEl: prevEl,
            },
            breakpoints: {
                451: {
                    spaceBetween: 20,
                    slidesPerView: 'auto',
                },
                993: {
                    spaceBetween: 20,
                    slidesPerView: 3,
                },
                1201: {
                    spaceBetween: 30,
                    slidesPerView: 4,
                },
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
            },
            on: {
                slideChange: function (swiper) {
                    swiper.activeIndex === slidesCount - 1 ? el.classList.add('slides-end') : el.classList.remove('slides-end')
                },
            }
        });
        swiperObserver(swiper);
    }
});
