import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let jobsSwiperWr = document.querySelectorAll('.jobs-swiper-wr');

jobsSwiperWr.forEach((el) => {

  if (el) {

    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 12,
      slidesPerView: 'auto',
      speed: 800,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        1201: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });

    window.swipers.push(swiper);

    swiperObserver(swiper);
  }
});

let jobCards = document.querySelectorAll('.job-card');
if(jobCards){
  jobCards.forEach(card=>{
    card.addEventListener('click', function(e){
      if(e.target.classList.contains('btn')){
        e.preventDefault();
      }
      /* fix vertical center alignment of popup*/
     /* updatePopupPosition();*/
    })
  })
}

window.updatePopupPosition =  function updatePopupPosition(){
  let popup = document.querySelector('#popup');
  let content = popup.querySelector('.popup__block');

  if (popup.clientHeight - 20 >= content.clientHeight) {
    popup.classList.add('justify-center');
  } else {
    popup.classList.remove('justify-center');
  }
}