import './utils/vh.utils.js';
import './utils/animate-on-view.utils'
import './utils/swiper-update.utils.js'

import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/header.component.js';
import './components/aos.component.js';
import './components/popups.component.js';

/*swiper carousels*/
import './components/study-swiper.component.js';
import './components/partners-swiper.component.js';
import './components/counts-swiper.component.js';
import './components/ts-swiper.component.js';
import './components/jobs-swiper.component.js';
import './components/title-swiper.component.js';
/*end of swiper carousels*/
import './components/meta-select.component.js';
import './libs/countUp.lib.js';


