import Swiper, {Autoplay, Navigation} from 'swiper';
import {swiperObserver} from '../utils/swiper.utils.js';
window.setSameHeight = function setSameHeight(elArr) {
  let minHeight = 0;
  let arr = document.querySelectorAll(elArr);
  if (arr) {
    arr.forEach(el => {
      el.style.minHeight = 0;
    })
    arr.forEach(el => {
      if (minHeight < el.clientHeight) {
        minHeight = el.clientHeight;
      }
    })
    arr.forEach(el => el.style.height = minHeight + 'px')
  }
}

let partnersSwiperWr = document.querySelectorAll('.partners-swiper-wr');
partnersSwiperWr.forEach((el) => {
    if (el) {
        let swiperEl = el.querySelector('.swiper');
        let nextEl = el.querySelector('.swiper-button-next');
        let prevEl = el.querySelector('.swiper-button-prev');
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let wrapper = swiperEl.querySelector('.swiper-wrapper');
        let swiper = new Swiper(swiperEl, {
            modules: [Autoplay, Navigation],
            observer: true, //needs to calculate swiper size
            observeParents: true, //needs to calculate swiper size
            spaceBetween: 8,
            slidesPerView: 'auto',
            speed: 800,
            threshold: 10,
            navigation: {
                nextEl: nextEl,
                prevEl: prevEl,
            },
            breakpoints: {
                601: {
                    spaceBetween: 30,
                    slidesPerView: 2,
                },

                769: {
                    spaceBetween: 30,
                    slidesPerView: 1,
                },
                1281: {
                    spaceBetween: 30,
                    slidesPerView: 2,
                },
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
            },
            on: {
                afterInit: function () {

                }
            }
        });
        swiperObserver(swiper);
    }
});

if(partnersSwiperWr){
  window.setSameHeight('.option-card li');
}


