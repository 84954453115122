window.swipers = [];
window.updateSwiper = function(selector){
  let swiperEl = typeof selector === 'object' ? selector : document.querySelector(`${selector}`);
  if (swiperEl){
    window.swipers.forEach(swiper=>{
      if(swiper.el === swiperEl || swiper === swiperEl){
        swiper.update();
      }
    })
  }
}
/*to use*/
/* updateSwiper('#jobs-section .swiper');*/
/* updateSwiper(swiper);*/