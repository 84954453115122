import Swiper, {
    Autoplay,
    EffectFade,
    Navigation,
    Controller,
} from 'swiper';
import {swiperObserver} from '../utils/swiper.utils.js';

let tsSwiperWr = document.querySelectorAll('.ts-swipers-wrapper');
tsSwiperWr.forEach((el) => {
    if (el) {
        let cardsSwiperEl = el.querySelector('.ts-card-swiper');
        let textSwiperEl = el.querySelector('.ts-text-swiper');
        let nextEl = el.querySelector('.swiper-button-next');
        let prevEl = el.querySelector('.swiper-button-prev');
        let slidesCount = cardsSwiperEl.querySelectorAll('.swiper-slide').length;

        let cardsSwiper = new Swiper(cardsSwiperEl, {
            modules: [Autoplay, Navigation, Controller],
            spaceBetween: 10,
            speed: 800,
            threshold: 10,
            slidesPerView: 'auto',
            navigation: {
                nextEl: nextEl,
                prevEl: prevEl,
            },
            breakpoints: {

                769: {
                    direction: 'vertical',
                },
            },
            slideToClickedSlide: true,

            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
            },
            on:{
                slideChange: function(swiper){
                    let wrapper = swiper.el.closest('.ts-card-swiper-wr')
                    swiper.activeIndex === slidesCount - 1 ? wrapper.classList.add('slides-end') : wrapper.classList.remove('slides-end')
                },
            }
        });

        let textSwiper = new Swiper(textSwiperEl, {
            modules: [EffectFade, Controller],
            spaceBetween: 20,
            slidesPerView: 1,
            speed: 800,
            threshold: 10,
            effect: 'fade',
            autoHeight: true,

            fadeEffect: {
                crossFade: true,
            },
            breakpoints: {

                769: {
                    autoHeight: false,
                },
            },

        });

          swiperObserver(cardsSwiper);

        cardsSwiper.controller.control = textSwiper;
        textSwiper.controller.control = cardsSwiper;
    }
});


