import Swiper, { Autoplay, EffectCreative } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let titleSwiperWr = document.querySelectorAll('.title-swiper-wr');
titleSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, EffectCreative],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      slidesPerView: 1,
      speed: 800,
      threshold: 10,
      centeredSlides: true,
      autoHeight: true,
      allowTouchMove: false,
      loop: slidesCount > 1,
      effect: "creative",
      creativeEffect: {
        limitProgress: 1,
        prev: {
          translate: [0, 0, -300],
          rotate: [180, 0, 0],
        },
        next: {
          translate: [0, 0, -300],
          rotate: [-180, 0, 0],
        },
      },

      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },

    });
    swiperObserver(swiper);
  }
});
