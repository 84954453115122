import Swiper, { Autoplay, Navigation} from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.js';


let studySwiperWr = document.querySelectorAll('.study-swiper-wr');
studySwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');


    swiperOnResize('(max-width: 992px)', swiperEl,
        {
          modules: [Autoplay, Navigation],
          slidesPerView: 1,
          spaceBetween: 20,
          speed: 800,
            autoHeight: true,
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          },
          breakpoints: {
            551: {
              slidesPerView: 2,
                autoHeight: false,
            },
            768: {
              slidesPerView: 2,
                autoHeight: false,
            },
          },
          navigation: {
            prevEl: prevEl,
            nextEl: nextEl,
          },
            on:{
                slideChange: function(swiper){

                    swiper.activeIndex === slidesCount - 1 ? el.classList.add('slides-end') : el.classList.remove('slides-end')
                },
            }
        },
    );
  }
});
